import React from 'react'
import { Builder } from '@builder.io/react'
import useGetProduct from '../../hooks/useGetProduct'
import ESW_CLASSNAMES from '../../esw/classnames'
import image from './images/dollar.svg'
import PriceStriked from '../../components/price-striked'

export default function Price({ sku }) {
    const product = useGetProduct(sku) ?? {}
    
    return <PriceStriked
        product={product}
        eswPriceStrikedClassname={ESW_CLASSNAMES.PAGE_PRICE}
        style={{ fontSize: 24 }}
        className=''
    />
}

Builder.registerComponent(Price, {
    name: 'Original Price',
    image,
    inputs: [
        {
            name: 'sku',
            type: 'string',
            required: true,
        }
    ]
})